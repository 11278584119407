import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IFormChange, IModule } from '@soleran/contracts';
import { GlobalDrawerService } from '../../../global-drawer/global-drawer.service';

@Component({
	selector: 'app-module-modal',
	templateUrl: './module-modal.component.html',
	styleUrl: './module-modal.component.scss',
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'app-module-modal'
	}
})
export class ModuleModalComponent {
	@Input() module?: IModule;

	constructor(private _globalDrawer: GlobalDrawerService) {}

	formState: IFormChange<IModule> = { valid: false, value: this.module };

	onFormChange(change: IFormChange<IModule>) {
		this.formState = change;
	}

	cancel() {
		this._globalDrawer.close();
	}
	submit() {
		this._globalDrawer.close(this.formState.value);
	}
}
